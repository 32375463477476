body {
  margin: 0 !important;
  padding: 0 !important;
  font-family: "Roboto", sans-serif;
}

.swal2-container {
  /* higher z-index than mui dialogs */
  z-index: 2000 !important;
}

@media screen and (min-width: 1088px) {
  main > .container {
    width: 98% !important;
    max-width: 98% !important;
  }
}

